<template>
  <div v-if="projet != false">
    <b-card class="card-custom mt-3 card-titre sticky-top" no-body style="z-index: 25;">
      <template #header>
        <div class="card-title">
          <b-button v-b-tooltip.hover title="Retour au prospect" variant="light-danger" :href="`/#/prospect/detail/${projet.client_id}`" class="btn-icon"><i class="fa fa-chevron-left"></i></b-button>
          <h3 class="card-label ml-2 p-3 bg-success text-white rounded">Devis <strong>#{{projet.id}} - {{projet.nom}} {{ projet.prenom }}</strong> <small class="text-white text-sm d-none d-lg-inline" v-if="projet.id != null">crée le {{ $api.timestampToDate(projet.date_create, false) }}</small></h3>
        </div>
        <div class="card-title text-center">
          <b-button :variant="$api.getParam('ProjetStatus', projet.status_id).color" size="lg">{{$api.getParam('ProjetStatus', projet.status_id).titre}}</b-button>
        </div>
        <div class="card-title">
          <Activity v-if="projet.id != null" :client_id="projet.client_id" class="mr-1" />
          <Message v-if="projet.id != null" :client_id="projet.client_id" :phones="[projet.telephone_1, projet.telephone_2]" :link="`${$api.url.app}/#/document/f/${devis.uuid}`" :obj="projet" type="Projet" class="mr-1" />
          <Mail v-if="projet.id != null" :client_id="projet.client_id" :link="`${$api.url.app}/#/document/f/${devis.uuid}`" :extrat="{'quotes': quotes}" :obj="projet" type="Projet" class="mr-1" />
          <AgendaEvent model="Client" :model_id="projet.client_id" :rdv="agenda" v-if="projet.id != null" ref="agenda" class="mr-1" />
          <b-dropdown v-b-tooltip.hover title="Changer le statut du devis" no-caret class="mr-1" size="md">
            <template #button-content>
              <i class="fas fa-thermometer-half fa-size"></i>
            </template>
            <b-dropdown-item v-for="(s,i) in $api.params.ProjetStatus" :key="i" :active="projet.status_id === s.id" @click="projet.status_id = s.id">{{s.titre}}</b-dropdown-item>
          </b-dropdown>
          <b-button v-if="$api.acl(15)" v-b-tooltip.hover title="Sauvegarder" variant="success" class="btn-icon" @click="save()" size="md">
            <i v-if="save_loader === false" class="fas fa-save fa-size"></i>
            <i v-else class="fas fa-spinner fa-spin fa-size"></i>
          </b-button>
        </div>
      </template>
    </b-card>
    <form ref="form">
      <b-tabs class="mt-4" id="main-tabs" pills card align="center" v-model="tabIndex">
        <b-tab title="Information du devis">
          <b-card-group deck>
            <b-card class="card-search">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Civilite">
                    <select v-model="projet.civilite" class="form-control">
                      <option value="Mr">Mr</option>
                      <option value="Mme">Mme</option>
                    </select>
                  </b-form-group>
                </b-col>
                <FieldForm model="Projet" :obj="projet" field="nom" />
                <FieldForm model="Projet" :obj="projet" field="prenom" />
                <b-col md="6">
                  <b-form-group label="Telephone 1:">
                    <Telephone v-model="projet.telephone_1" :edit="true" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Telephone 2:">
                    <Telephone v-model="projet.telephone_2" :edit="true" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Email:">
                    <b-input-group>
                      <template #append>
                        <b-button variant="light-danger" class="btn-icon"  size="md" :href="`mailto:${projet.email}`"><i class="fa fa-envelope"></i></b-button>
                      </template>
                      <b-form-input v-model="projet.email"></b-form-input>
                    </b-input-group>
                  </b-form-group>                  
                </b-col>                
                <FieldForm model="Projet" :obj="projet" field="adresse" />
                <FieldForm model="Projet" :obj="projet" field="adresse_2" />
                <CodePostal :obj="projet" />
                <FieldForm model="Projet" :obj="projet" field="ville" />
                <b-col :md="projet.status_id === 9 ? 3 : 6">
                  <b-form-group label="Date d'éffet:">
                    <input type="date" class="form-control" required v-model="projet.date_effet" :min="$api.moment().format('YYYY-MM-DD')" :max="max_d_effet">
                  </b-form-group>
                </b-col>
                <b-col v-if="[9,12].includes(projet.status_id)" md="3">
                  <b-form-group label="Date de resiliation:">
                    <input type="date" class="form-control" required v-model="projet.contrat.date_resiliation">
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Commerciale:">
                    <input class="form-control" :value="$api.p('User', projet.user_id).titre" disabled/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>            
          </b-card-group>
          <b-card class="card-search mt-3">                   
            <b-table-simple hover striped responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Assuré</b-th>
                  <b-th>Civilite</b-th>
                  <b-th>Nom</b-th>
                  <b-th>Prenom</b-th>
                  <b-th>Date de naissance</b-th>
                  <b-th>Regime</b-th>
                  <b-th>Numero secu</b-th>                  
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>Principal</b-td>
                  <b-td>
                    <select v-model="projet.civilite" class="form-control">
                      <option value="Mr">Mr</option>
                      <option value="Mme">Mme</option>
                    </select>
                  </b-td>
                  <b-td><Input type="text" v-model="projet.nom" /></b-td>
                  <b-td><Input type="text" v-model="projet.prenom" /></b-td>
                  <b-td><Input type="date" v-model="projet.date_naissance" required/></b-td>
                  <b-td>
                    <select v-model="projet.regime_id" class="form-control" required>
                      <option v-for="(r, i) in $api.params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                    </select>
                  </b-td>
                  <b-td><b-form-input type="text" v-model="projet.numero_secu" minlength="15" maxlength="15"></b-form-input></b-td>
                  <b-td></b-td>
                </b-tr>
                <b-tr v-if="projet.conjoint.regime_id != null">
                  <b-td>Conjoint</b-td>
                  <b-td>
                    <select v-model="projet.conjoint.civilite" class="form-control">
                      <option value="Mr">Mr</option>
                      <option value="Mme">Mme</option>
                    </select>
                  </b-td>
                  <b-td><Input type="text" v-model="projet.conjoint.nom" /></b-td>
                  <b-td><Input type="text" v-model="projet.conjoint.prenom" /></b-td>
                  <b-td><Input type="date" v-model="projet.conjoint.date_naissance" :required="projet.conjoint.regime_id != null" /></b-td>
                  <b-td>
                    <select v-model="projet.conjoint.regime_id" class="form-control">
                      <option :value="null">Aucun</option>
                      <option v-for="(r, i) in $api.params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                    </select>
                  </b-td>
                  <b-td><b-form-input type="text" v-model="projet.conjoint.numero_secu" minlength="15" maxlength="15"></b-form-input></b-td>                  
                </b-tr>
                <b-tr v-for="(e, i) in projet.enfants" :key="i">
                  <b-td>Enfant {{  i + 1 }}</b-td>
                  <b-td>
                    <select v-model="e.civilite" class="form-control">
                      <option value="Mr">Mr</option>
                      <option value="Mme">Mme</option>
                    </select>
                  </b-td>
                  <b-td><Input type="text" v-model="e.nom" /></b-td>
                  <b-td><Input type="text" v-model="e.prenom" /></b-td>
                  <b-td><Input type="date" v-model="e.date_naissance" required/></b-td>                  
                  <b-td>
                    <select v-model="e.regime_id" class="form-control" required>
                      <option :value="null">Aucun</option>
                      <option v-for="(r, i) in $api.params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                    </select>
                  </b-td>
                  <b-td><b-form-input type="text" v-model="e.numero_secu" minlength="15" maxlength="15"></b-form-input></b-td>                  
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
          <b-card class="card-search mt-3"> 
            <div class="d-block text-right mb-2">
              <b-button v-if="$api.acl(25)" v-b-tooltip.hover title="Ajouter un produit personnaliser" variant="light-warning" size="sm" class="btn-icon" v-b-modal.custom_produit>
                <i class="fas fa-calculator"></i>
              </b-button>              
              <b-button v-b-tooltip.hover title="Ajouter d'autre produit" variant="light-info" size="sm" class="btn-icon ml-1" v-b-modal.tarificateur>
                <i class="fa fa-plus"></i>
              </b-button>              
            </div>
            <b-table-simple hover striped responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Mutuelle</b-th>
                  <b-th>Formule</b-th>
                  <b-th>Tarif</b-th>
                  <b-th>Document</b-th>
                  <b-th>Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(q,i) in quotes" :key="i" :class="projet.contrat.join_key === q.join_key ? 'bg-success' : ''">
                  <b-td>{{ q.mutuelle }}</b-td>
                  <b-td>                    
                    {{ q.titre }}
                  </b-td>
                  <b-td>{{ $api.price(q.tarif) }}</b-td>
                  <b-td>
                    <b-button-group>
                      <b-button size="sm" variant="info" v-for="(d, di) in q.docs.filter(y => y.value != null)" :key="di" :href="$api.link(d.value)" target="_blank" v-b-tooltip.hover.left :title="d.key" class="border">{{ d.key[0] }}</b-button>
                    </b-button-group>
                  </b-td>
                  <b-td>       
                    <b-button-group size="sm">
                      <b-button class="btn-icon"  v-b-tooltip.hover.left title="Creation du contrat" variant="primary" @click="save('contrat', {q:q,i:i})">
                        <i class="fas fa-file-signature"></i>
                      </b-button>
                      <b-button class="btn-icon"  v-b-tooltip.hover.left title="Cliquez pour mettre en favorie" :variant="q.favorie ? 'warning' : 'secondary'" @click="setFavorie(i)">
                        <i class="fas fa-trophy"></i>                        
                      </b-button>
                      <b-button v-if="quotes.length > 1" class="btn-icon" variant="danger" @click="removeQuote(i)"  v-b-tooltip.hover.left title="Supprimer la garantie">
                        <i class="fa fa-trash"></i>
                      </b-button>
                    </b-button-group>                             
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-tab>
        <b-tab title="Documents" v-if="projet.id != null">
          <GED model="Projet" :model_id="projet.id" />
        </b-tab>
        <b-tab title="Garanties" v-if="projet.id != null">
          <b-card class="card-search">
            <div class="d-block text-right mb-2">
              <b-button v-b-tooltip.hover title="Ajouter / Supprimer la garantie actuelle du client" variant="light-info" size="sm" class="btn-icon" @click="addActuelle()">
                <i class="fa fa-plus" :class="projet.actuelle.garantie === false ? 'fa-plus' : 'fa-trash'"> </i>
              </b-button>
              <b-button variant="light-primary" size="sm" class="btn-icon ml-1" @click="checkForm()">
                <i class="fa text-white" :class="!reload? 'fa-sync' : 'fa-spinner fa-spin'"></i>
              </b-button>
            </div>
            <b-table-simple hover striped class="garanties-block">
              <!-- <b-thead head-variant="light" class="sticky-top"> -->
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>Titre</b-th>
                  <b-th v-for="(q,i) in projet.quotes" :key="i" class="text-center"> {{ q.titre }}
                    <br /> {{ $api.price(q.tarif) }}
                  </b-th>
                  <b-th class="text-center" v-if="projet.actuelle.garantie != false">
                    <input placeholder="Garantie actuelle" v-model="projet.actuelle.titre" />
                    <input placeholder="Montant de la garantie" v-model="projet.actuelle.tarif" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <div v-for="(g, gi) in garanties" :key="gi" class="d-contents">
                  <b-tr>
                    <b-td :colspan="projet.quotes.length + (!projet.actuelle.garantie ? 1 : 2)" class="text-center bg-light-info">
                      <strong>{{ gi }}</strong>
                    </b-td>
                  </b-tr>
                  <b-tr v-for="(f, fi) in g" :key="fi">
                    <b-td v-for="(d, di) in f" :key="di" :class="di > 0 ? 'text-center' : ''">
                      <span>{{ d }}</span>
                    </b-td>
                    <b-td class="text-center" v-if="projet.actuelle.garantie != false">
                      <input v-model="projet.actuelle.garantie[gi][fi].value" :placeholder="projet.actuelle.garantie[gi][fi].key" />
                    </b-td>
                    <!-- <b-td v-for="(d, di) in projet.actuelle[fi]" :key="di" :class="di > 0 ? 'text-center' : ''"><span>{{ d }}</span></b-td>                                     -->
                  </b-tr>
                  <!-- <b-tr v-for="(f, fi) in projet.actuelle[g]" :key="fi"><b-td v-for="(d, di) in f" :key="di" :class="di > 0 ? 'text-center' : ''"><span>{{ d }}</span></b-td></b-tr> -->
                </div>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-tab>
        <b-tab title="Suivi" v-if="projet.id != null">
          <b-card class="card-search">
            <div class="d-block text-center mb-3">
              <b-button v-if="devis != false" variant="primary" :href="`${$api.url.app}/#/document/f/${devis.uuid}`" target="_blank"> Lien du client </b-button>
            </div>
            <b-table-simple hover striped responsive class="mt-3">
              <b-thead head-variant="light" class="sticky-top">
                <b-tr>
                  <b-th>Date</b-th>
                  <b-th>Duree Total</b-th>
                  <b-th>Duree par page</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(f,i) in devis.track" :key="i">
                  <b-td>{{ $api.timestampToDate(f.date)}}</b-td>
                  <b-td>{{ $api.moment.utc(f.duree*1000).format('HH:mm:ss') }}</b-td>
                  <b-td>
                    <b-button-group>
                      <b-button size="sm" variant="primary" class="border" v-for="(p, i) in f.pages" :key="i">{{ p.key }}: <strong>{{ $api.moment.utc(p.duree*1000).format('HH:mm:ss') }}</strong>
                      </b-button>
                    </b-button-group>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-tab>
        <b-tab title="Étude personnalisée" v-if="projet.id != null && $api.acl(16)">
          <form ref="etude_perso">
            <b-card class="card-search mt-3">
              <b-row class="mb-5">
                <b-col cols="6">
                  <span class="h6">Étude personnalisée</span>
                </b-col>
                <b-col cols="6" class="text-right">
                  <Mail v-if="etude != false" :client_id="projet.client_id" :link="`${$api.url.app}/#/document/f/${etude.uuid}`" :extrat="{'quotes': quotes}" :obj="projet" type="Contrat" class="mr-1 d-inline" />
                  <b-button v-b-tooltip.hover title="Voir l'etude" v-if="etude != false" target="_blank" variant="light-info" class="btn-icon" :href="`${$api.url.app}/#/document/f/${etude.uuid}`">
                    <i class="fa fa-eye fa-size"></i>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Genere une nouvelle etude" variant="light-primary" class="btn-icon ml-1" @click="save('etude')">
                    <i class="fa text-white fa-size" :class="!etude_load ? 'fa-file-pdf' : 'fa-spinner fa-spin'"></i>
                  </b-button>
                </b-col>
              </b-row>
              <Etude :projet="projet" />
            </b-card>
          </form>
        </b-tab>
        <b-tab title="Modifications" v-if="projet.id != null">
            <Log :logs="logs" />
        </b-tab>
      </b-tabs>
      <b-card class="mt-3">
        <Commentaire ref="commentaire" v-model="projet.commentaire" />
      </b-card>
    </form>
    <b-modal ref="tarificateur" id="tarificateur" hide-footer title="Tarificateur" size="xl">      
      <b-row>
        <b-col :md="tarif.selected.length > 0 ? 9 : 12">
          <Datatable ref="liste_tarif" :data_filters="tarif.filters" :route="`/client/price/liste/${projet.client_id}`" :fields="tarif.fields" titre="Liste des tarifs" :sort="tarif.sort">      
            <template #customField>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Par mutuelle">
                    <Input type="select-model" v-model="tarif.filters.mutuelle_id" :options="$api.params.Mutuelle" /> 
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Par garantie">
                    <Input type="select-model" v-model="tarif.filters.titre" :options="produitsFieltered" /> 
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-button class="mt-5" variant="light-danger" @click="tarif.filters.mutuelle_id = forteMarge; $refs.liste_tarif.datatableInit()">Forte marge</b-button>
                </b-col>
              </b-row>
            </template>             
            <template #cell(produit)="data">
              <small v-for="(d, i) in getProduit(data.value)" :key="i" class="d-block">{{ d.key }}: <strong>{{ d.value }}</strong></small>
            </template>
            <template #cell(tarif)="data">
              {{ $api.price(data.value) }}
            </template>
            <template #cell(action)="data">
              <b-form-checkbox v-model="tarif.selected" :key="data.item.id" :value="data.item" size="lg" inline >
                <i class="fa fa-star text-warning" v-if="checkSelect(data.item.id)"></i>
              </b-form-checkbox>              
            </template>              
          </Datatable>

        </b-col>
        <b-col md="3" v-if="tarif.selected.length > 0">
          <div class="sticky-top" style="margin-top:50px">
            <b-list-group>
              <b-list-group-item v-for="(s, i) in tarif.selected" :key="i" href="javascript:void(0)">{{ s.titre }} <strong>{{ $api.price(s.tarif) }}</strong><b-button size="xs" class="btn-icon ml-4" variant="danger" @click="tarif.selected.splice(i, 1);"><i class="fa fa-trash"></i></b-button></b-list-group-item>                  
              <b-list-group-item><b-button variant="success" @click="addQuote()" block>Sauvegarder</b-button></b-list-group-item>
            </b-list-group>                
          </div>
        </b-col>
      </b-row>
    </b-modal>   
    <b-modal ref="custom_produit" id="custom_produit" hide-footer title="Produit personnalisé" size="xl">      
      <b-form>         
        <b-row class="mt-3">
          <b-col md="4">            
            <b-form-group label="Mutuelle">
              <b-form-input :value="$api.p('Mutuelle',quoteTmp.mutuelle_id).titre" disabled/>
            </b-form-group>
          </b-col>
          <b-col md="6">            
            <b-form-group label="Formule">
              <Input v-model="quoteTmp.titre" type="text" />
            </b-form-group>
          </b-col>
          <b-col md="2">            
            <b-form-group label="Tarif">
              <Input v-model="quoteTmp.tarif" type="price" />
            </b-form-group>
          </b-col>
        </b-row>  
        <b-row class="mt-3">
          <b-col md="12" class="text-center">
            <b-button variant="primary" @click="customQuote()">Ajouter</b-button>
          </b-col>
        </b-row>    
      </b-form>
    </b-modal> 
    <b-modal ref="modal_contrat" id="modal_contrat" hide-footer title="Complement d'information">      
      <p>Avant de sauvegarder, il nous faut un complément d'information !</p>
      <p>Merci de renseigner la garantie sélectionnée par le client.</p>
      <b-form>         
        <b-row class="mt-3">
          <b-col md="12">            
            <b-form-group label="Garantie">
              <select v-model="projet.contrat.join_key" class="form-control">
                <option v-for="(q,i) in quotes" :key="i" :value="q.join_key">{{ q.titre }}</option>
              </select>
            </b-form-group>
          </b-col>          
        </b-row>  
        <b-row class="mt-3">
          <b-col md="12" class="text-center">
            <b-button variant="primary" @click="save('contratInfo')">Ajouter</b-button>
          </b-col>
        </b-row>    
      </b-form>
    </b-modal> 
    <b-modal ref="modal_resil" id="modal_resil" hide-footer title="Complement d'information">      
      <p>Avant de sauvegarder, il nous faut un complément d'information !</p>
      <p>Merci de renseigner la date de resiliation.</p>
      <b-form>         
        <b-row class="mt-3">
          <b-col md="12">            
            <b-form-group label="Garantie">
              <Input type="date" v-model="projet.contrat.date_resiliation"/>
            </b-form-group>
          </b-col>          
        </b-row>  
        <b-row class="mt-3">
          <b-col md="12" class="text-center">
            <b-button variant="primary" @click="save('resil')">Ajouter</b-button>
          </b-col>
        </b-row>    
      </b-form>
    </b-modal> 
  </div>
</template>
<script>
import FieldForm from '@/components/FieldForm';
import Telephone from '@/components/Telephone';
import Commentaire from '@/components/Commentaire';
import AgendaEvent from '@/components/AgendaEvent';
import Input from '@/components/InputForm';
import GED from '@/components/GED';
import Message from '@/components/Message';
import Mail from '@/components/Mail';
import CodePostal from '@/components/CodePostal';
import Datatable from '@/components/Datatable';
import Activity from '@/components/Activity';
import Etude from '@/components/Etude';
import Log from '@/components/Log';
export default {
  name: "ProjetNouveau",
  components: {
    FieldForm,
    Commentaire,     
    AgendaEvent,
    Telephone,
    Input,
    GED,
    Message,
    Mail,
    CodePostal,
    Datatable,
    Activity,    
    Etude,
    Log
  },
  data(){
    return{
      tabIndex:0,
      projet: false,       
      garanties: false,
      quotes:false,    
      meta:false,
      agenda:[],
      files:[],
      logs: [],
      save_loader:false,
      mail_load: false,
      reload:false,
      etude_load: false,
      tmpCommentaire:null,
      createContrat: true,
      tarif:{
        selected: [],
        filters:{
          titre:[],
          mutuelle_id:[]
        },
        fields: [
          {
            key: 'id',
            label: 'Ref',
            sortable:false,
          },
          {
            key: 'action',
            label: 'Action',
            sortable:false,
          },
          {
            key: 'mutuelle.titre',
            label: 'Mutuelle',
            sortable:false,
          },
          {
            key: 'titre',
            label: 'Titre',
            sortable:false,
          },
          {
            key: 'produit',
            label: 'Recap',
            sortable:false,
          },
          {
            key: 'tarif',
            label: 'Tarif',
            sortable:true,
          }, 
        ],
        sort:{
          key: 'tarif',
          value: 'ASC'
        },
      },
      quoteTmp:{
        mutuelle_id: 24,
        join_key:"Autre produit",
        tarif: 0,
        titre: null,
        favorie: false,
        logo: null
      }
    }
  },
  watch:{
    'projet.regime_id'(v){      
      this.projet.regime_id  = v === undefined ? null : v;
      if(this.projet.regime_id === null){
        this.projet.date_naissance = null;
      }      
    },        
    'projet.contrat.join_key'(v){
      var check = this.quotes.find(q => q.join_key === v);
      if(check === undefined){
        return false;
      }
      this.projet.contrat.titre = check.titre;
      this.projet.contrat.mutuelle_id = check.mutuelle_id;
      this.projet.contrat.montant = check.tarif;
      this.projet.contrat.date_validation = this.$api.moment().format("YYYY-MM-DD");
    },
    'projet.status_id'(n,o){
      if(n != o && n === 12 && o != undefined){
        this.$refs.modal_resil.show();
      }
      
    }
  },
  computed:{
    mails(){
      return this.$api.params.Template.filter(t => t.type === 'ProjetMail');
    },
    devis(){
      var check = this.files.find(f => f.template_id === 1);
      if(check === undefined){
        return false;
      }
      return check;
    },
    etude(){
      var check = this.files.filter(f => this.$api.params.StatusLock.etudeTpl.includes( f.template_id));
      check.sort((a, b) => b.date_create - a.date_create);
      if(check.length === 0){
        return false;
      }
      return check[0];
    },
    forteMarge(){
      var ids = [];      
      this.$api.params.Mutuelle.forEach(e => {
        if(e.forte_marge === true){
          ids.push(e.id);
        }
      });
      return ids;
    },
    max_d_effet(){
      var next =  this.$api.moment().add(1, 'Y');
      return next.format('YYYY-MM-DD');
    },
    etude_others_files(){
      var res = [];
      var ids = [9,10];
      ids.forEach((id) => {
        var check = this.$api.params.Template.find(t => t.id === id);        
        if(check === undefined){
          return 
        }
        res.push(check);
      });
      return res;
    },
    produitsFieltered(){          
      if(this.tarif.filters.mutuelle_id.length === 0){
        return this.meta.titres
      }
      return this.meta.titres.filter(t => this.tarif.filters.mutuelle_id.includes(t.mutuelle_id));
    }
  },
  beforeMount() {
  },
  mounted() {
    var route = this.$route;
    if(route.name.indexOf("Nouveau") > -1){
      route.query.quotes = route.query.quotes.map(tmp => tmp = parseInt(tmp));
      var params = {
        client_id: parseInt(route.params.id),
        quotes: route.query.quotes
      }
      this.$api.ajax('/projet/new', params, res => {
        if(res.status){
          this.projet = res.data.projet;         
          this.quotes = res.data.quotes; 
          this.save()         
        }
      })
    }else{
      this.$api.ajax('/projet/'+this.$route.params.id, null, res => {
        if(res.status){          
          this.projet = res.data.projet;
          this.garanties = res.data.garanties;
          this.quotes = res.data.quotes;
          this.files = res.data.files;          
          this.meta = res.data.meta;  
          this.agenda = res.data.agenda;          
          this.logs = res.data.logs;
        }
      });
    }    
  },
  methods: {
    checkForm(){
      var form = this.$refs.form;
      var fields = form.querySelectorAll("[required='required']");
      var valide = true;
      fields.forEach((item) => {
        if(item.checkValidity() === false){
          item.classList.add("need-validation");
          console.log(item);
          valide = false;
        }else{          
          item.classList.remove("need-validation");
        };
      });
      if(valide === false){
        this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
          title: 'Champs',
          variant: 'warning',
          solid: true
        });
        this.tabIndex = 0;
        return false;
      }
      this.save('reload')
    },
    save(event = '', extrat = false){
      if(this.save_loader === true){
        return false;
      }      
      if(this.checkContrat() === false){
        return false;
      }
      this.save_loader = true;
      this.$api.ajax('/projet/create', this.projet, res => {
        if(res.status === true){
          this.projet = res.data.projet;
          this.garanties = res.data.garanties;
          this.quotes = res.data.quotes;
          this.files = res.data.files;          
          this.meta = res.data.meta;  
          this.agenda = res.data.agenda;          
          this.logs = res.data.logs;
          if(this.$route.path.indexOf("nouveau") > -1){
            this.$router.push({path:"/devis/detail/"+this.projet.id});
          }
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }else{
          if(res.data === "Merci de remplir l'etude"){
            this.save_loader = false;        
            this.checkEtude();            
            return false;
          }
        }
        this.save_loader = false;        
        if(event === 'reload'){
          this.reloadPrice();
        }else if(event === ''){
          // if([1,2].includes(this.projet.status_id)){
          //   this.$refs.agenda.open();
          // }        
        }else if(event === 'etude'){
          this.generateEtude();
        }else if(event === 'contrat'){
          this.openBIA(extrat.q,extrat.i);
        }else if(event === 'contratInfo'){
          this.$refs.modal_contrat.hide();
        }else if(event === 'resil' && this.projet.contrat.date_resiliation != null){
          var date = this.$api.moment(this.projet.contrat.date_resiliation);
          var tmp = date.add(9, 'months');
          var params = {
            "id": null,
            "model": "Client",
            "model_id": this.projet.client_id,
            "data": {
              "titre": `#${this.projet.id} ${this.projet.nom} ${this.projet.prenom}`,
              "date": tmp.format("YYYY-MM-DD"),
              "heure": "09:30",
              "description": null,
              "categorie_id": 8,
              "url": location.href,
            }
          }
          this.$api.ajax('/agenda/create', params, res => {
            if(res.status === true){
              this.$refs.modal_resil.hide()
            }
          })
        }
      })
    },
    reloadPrice(){
      if(this.reload === true){
        return true;
      }
      this.reload = true;
      this.$api.ajax('/projet/price/'+this.projet.id, null, res => {
        if(res.status){
          this.projet = res.data.projet;
          this.garanties = res.data.garanties;
        }
        this.reload = false;
      });
    },    
    addActuelle(){
      if(this.projet.actuelle.garantie === false){
        this.$api.ajax('/produit/new', null, res => {
          if(res.status === true){
            this.projet.actuelle.garantie = res.data.garantie 
          }
        });
      }else{
        this.projet.actuelle.garantie = false; 
      }      
    },
    removeQuote(i){      
      this.$refs.commentaire.tmpCommentaire = "Supprimer - "+this.quotes[i].titre+" a "+this.$api.price(this.quotes[i].tarif);
      this.projet.quotes.splice(i, 1);
      this.quotes.splice(i, 1);      
      this.$refs.commentaire.addCommentaire();            
    },
    checkEtude(){
      var form = this.$refs.etude_perso;
      var fields = form.querySelectorAll("[required='required']");
      var test = true;
      var meilleurs = ['meilleur_prix', 'meilleur_rapport_prix', 'meilleure_couverture'];
      var count = 0;
      meilleurs.forEach(e => {
        if(this.projet.etude[e] === 'Oui'){
          count++;
        }
      });
      if(count != 1){
        this.$bvToast.toast('Merci de repondre Oui a une seule option', {
          title: 'Prix ou Rapport Garanties/Prix ou Couverture',
          variant: 'warning',
          solid: true
        });
        return false
      }
      fields.forEach((item) => {
        if(item.checkValidity() === false){
          item.classList.add("need-validation");
          test = false;
        }else{          
          item.classList.remove("need-validation");
        };
      });            
      if(test === false){
        this.$bvToast.toast('Merci de remplir tout les champs en rouge', {
          title: 'Champs',
          variant: 'warning',
          solid: true
        });
        this.tabIndex = 4;
        return false;
      }
      return true;
    },
    generateEtude(){  
      if(this.checkEtude() === false){
        return false;
      }
      if(this.etude_load === true){
        return false;
      }
      this.etude_load = true;
      this.$api.ajax('/projet/etude/'+this.projet.id, null, res => {
        if(res.status === true){
          this.projet = res.data.projet;
          this.garanties = res.data.garanties;
          this.quotes = res.data.quotes;
          this.files = res.data.files;          
          this.meta = res.data.meta;          
          this.etude_load = false;
        }
      })
    },
    setFavorie(i){    
      this.quotes.forEach((q) => {
        q.favorie = false;
      })  
      this.projet.quotes.forEach((q) => {
        q.favorie = false
      });
      this.projet.quotes[i].favorie = true;      
      this.quotes[i].favorie = true;      
    },  
    openBIA(q,i){
      if(q.bia === false){
        this.$bvToast.toast('Il semblerait que pour le moment le BIA ne soit pas disponible !', {
            title: 'Oups !',
            variant: 'warning',
            solid: true
          });
        return false;
      }      
      this.$router.push({path:`/devis/contrat/${this.projet.id}?index=${i}`});
    },
    getProduit(data){      
      if(data === false){
        return []
      }      
      var res = [];
      this.$api.params.StatusLock.tarificateur_preview.forEach(elt => {        
        var key = elt.a;
        var value = elt.b;        
        var check = data.garantie[key];        
        if(check != undefined){
          check = Object.values(check);
          var check2 = check.find( f => f.key === value);
          if(check2 != undefined){
            res.push(check2);
          }
        }
      });
      return res;      
    },
    checkSelect(quote_id){
      return this.tarif.selected.filter(s => s.id === quote_id).length === 1;
    },   
    addQuote(){
      var params = {
        projet_id: this.projet.id,
        quotes: this.tarif.selected
      }
      this.$api.ajax('/projet/quotes', params, res => {
        if(res.status === true){          
          this.projet = res.data.projet;
          this.garanties = res.data.garanties;
          this.quotes = res.data.quotes;
          this.files = res.data.files;
          this.$refs.tarificateur.hide();
          this.tarif.selected = [];
        }
      })
    },
    canSign(mutuelle_id){
      if(this.$api.acl(14) === false){
        return false;
      }
      var user_ok = [2,3];
      if(user_ok.includes(this.$api.user.id)){
        return true;
      }      
      if(this.$api.params.StatusLock.bia_actif.includes(mutuelle_id)){
        return true;
      }
      return false;
    },        
    customQuote(){
      var quote = JSON.parse(JSON.stringify(this.quoteTmp));
      this.projet.quotes.push(quote);
      this.$refs.custom_produit.hide();
      this.save();     
      this.quoteTmp = {
        mutuelle_id: 24,
        join_key:"Autre produit",
        tarif: 0,
        titre: null,
        favorie: false,
        logo: null
      }
      
    },
    checkContrat(){
      var flag = true;
      if(this.projet.status_id === 8){        
        var check = this.projet.quotes.find(q => q.join_key === this.projet.contrat.join_key);        
        if(check === undefined){
          this.$refs.modal_contrat.show();  
          flag = false;        
        }
      }      
      return flag;
    }
  }
}
</script>
<style scoped>
.garanties-block{
  table-layout: fixed;
  width: 100%;
}
.garanties-block input, .garanties-block textarea {
  display: inline;
  font-family: inherit;
  border: none;
  padding: 0 10px;
  margin: 0;
  width: 100%;
  background: none;
  text-align: center;
  font-size: 0.95rem;
}
</style>
<style>

#main-tabs__BV_tab_controls_ > .nav-item .nav-link.active{
  color: white !important;
}
#main-tabs__BV_tab_container_ > .tab-pane.card-body{
  padding: 0px;
  margin-top: 10px;
}
#main-tabs > .card-header:first-of-type {
  padding: 1rem 1.25rem !important;
}
.liste-custom {
  position: absolute;
  z-index: 9;
  width: 92%;
}
.select-pays select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.d-contents {
  display: contents;
}
.need-validation {
  border: solid 2px red;
}
</style>
